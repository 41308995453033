<template>
  <v-container id="alert-notice" fluid tag="section">
    <v-row justify="start" class="mb-5">
      <!-- 四分區塊 -->
      <v-col
        v-for="({ actionIcon, ...attrs }, i) in stats"
        :key="i"
        cols="12"
        md="6"
        lg="3"
      >
        <material-stat-card-simple v-bind="attrs">
          <template #actions>
            <v-icon class="mr-2" small v-text="actionIcon" />
            <!-- <div class="text-truncate">
              {{ actionText }}
            </div> -->
          </template>
        </material-stat-card-simple>
      </v-col>
    </v-row>
    <v-row justify="start">
      <v-col cols="12">
        <div class="mb-5">
          <h1>API 取回資料</h1>
          <p>
            https://huangling-event-trigger-others.azurewebsites.net/HuangLin/api/HttpExample?code=NSyocTIdbNelQh22SFakWORKRWAW10JN5xQmGnwolM7Amfoett1VhQ==
          </p>

          <!-- <v-btn
            depressed
            color="primary"
            @click="getData"
          >
            取回資料
          </v-btn> -->
        </div>
        <div class="mb-5">
          <h3>所有資料</h3>
          {{ capiData }}
        </div>

        <hr class="mb-5" />

        <div class="mb-5">
          <h3>第一層過濾 ( data )</h3>
          {{ capiDataData }}
        </div>

        <hr class="mb-5" />

        <div class="mb-5">
          <h3>第二層過濾 ( data: main_comp / cust_name / gw_name )</h3>
          公司：{{ capiDataDataMaincomp }} <br />客戶：{{
            capiDataDataCustname
          }}
          <br />
          Gateway：{{ capiDataDataGwname }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AlertNotice',
  data () {
    const capiData = '讀取中……'
    const capiDataData = '讀取中……'
    const capiDataDataMaincomp = '讀取中……'
    const capiDataDataCustname = '讀取中……'
    const capiDataDataGwname = '讀取中……'
    return {
      capiData: capiData,
      capiDataData: capiDataData,
      capiDataDataMaincomp: capiDataDataMaincomp,
      capiDataDataCustname: capiDataDataCustname,
      capiDataDataGwname: capiDataDataGwname,
      stats: [
        {
          actionIcon: 'mdi-alert',
          color: '#E91E63',
          icon: 'mdi-waveform',
          title: '暫存器: 19 紀錄中',
          value: '突波'
        },
        {
          actionIcon: 'mdi-tag',
          color: 'primary',
          icon: 'mdi-chart-arc',
          title: '暫存器: 20 紀錄中',
          value: '東側下層過載'
        },
        {
          actionIcon: 'mdi-calendar-range',
          color: 'orange',
          icon: 'mdi-store',
          title: '暫存器: 21 紀錄中',
          value: '西側下層過載'
        },
        {
          actionIcon: 'mdi-history',
          color: 'info',
          icon: 'mdi-fuel',
          title: '暫存器: 22 紀錄中',
          value: '東側上層過載'
        },
        {
          actionIcon: 'mdi-history',
          color: 'info',
          icon: 'mdi-home-alert',
          title: '暫存器: 22 紀錄中',
          value: '西側上層過載'
        },
        {
          actionIcon: 'mdi-alert',
          color: '#E91E63',
          icon: 'mdi-waveform',
          title: '暫存器: 19 紀錄中',
          value: '突波'
        },
        {
          actionIcon: 'mdi-tag',
          color: 'primary',
          icon: 'mdi-chart-arc',
          title: '暫存器: 20 紀錄中',
          value: '東側下層過載'
        },
        {
          actionIcon: 'mdi-calendar-range',
          color: 'success',
          icon: 'mdi-store',
          title: '暫存器: 21 紀錄中',
          value: '西側下層過載'
        },
        {
          actionIcon: 'mdi-history',
          color: 'amber',
          icon: 'mdi-fuel',
          title: '暫存器: 22 紀錄中',
          value: '東側上層過載'
        },
        {
          actionIcon: 'mdi-history',
          color: 'amber',
          icon: 'mdi-home-alert',
          title: '暫存器: 22 紀錄中',
          value: '西側上層過載'
        }
      ]
    }
  },

  created () {
  },

  mounted () {
    // for 開發環境
    // const self = this
    // self.$http
    //   .get(
    //     '/HuangLin/api/HttpExample?code=NSyocTIdbNelQh22SFakWORKRWAW10JN5xQmGnwolM7Amfoett1VhQ=='
    //   )
    //   .then(response => {
    //     self.capiData = response
    //     self.capiDataData = response.data
    //     self.capiDataDataMaincomp = response.data.main_comp
    //     self.capiDataDataCustname = response.data.cust_name
    //     self.capiDataDataGwname = response.data.gw_name
    //   })

    // for 佈署環境
    const self = this
    self.$http.get('https://huangling-event-trigger-others.azurewebsites.net/api/HttpExample?code=NSyocTIdbNelQh22SFakWORKRWAW10JN5xQmGnwolM7Amfoett1VhQ==')
      .then(response => {
        self.capiData = response
        self.capiDataData = response.data
        self.capiDataDataMaincomp = response.data.main_comp
        self.capiDataDataCustname = response.data.cust_name
        self.capiDataDataGwname = response.data.gw_name
      })
  },

  methods: {
  }
}
</script>

<style lang="scss">
.v-input__slot > .v-label {
  -webkit-order: 1;
  order: 1;
  font-weight: 800;
}
.v-input__slot > .v-input--selection-controls__input {
  -webkit-order: 2;
  order: 2;
}
.w-100 {
  width: 100% !important;
}
.v-messages {
  margin-bottom: 1em;
}
#alert-notice .v-card--material > .v-card__title > .v-sheet {
  width: 76px !important;
}
</style>
